<template>
  <CategoriesList />
</template>

<script>
export default {
  components: {
    CategoriesList: () =>
      import('@/components/stock/categories/CategoriesList'),
  },
}
</script>